.modal-main-component {
    width: 900px;
}
.modal-main-content {
 
}
.modal-text-box {
    width: 453px;
}
.accordion-container {
    background-color: "red";
    font-size: "16px";
    font-weight: "600";
    box-shadow: 'none';
    border: "#e5e7eb 1px solid";
    border-radius: "8px";
}
.drop-file-input {
    position: relative;
    /* width: 400px; */
    height: 200px;
    /* border: 2px dashed var(--border-color); */
    /* border: 2px dashed #8f27c133; */
    border: 1px solid black;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    height: 100%;
    cursor: pointer;
}

.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
    color: #ccc;
    font-weight: 600;
    padding: 10px;
}

.drop-file-input:hover{
    background-color: #8f27c126;
    color: #8f27c1;
    border-color: #8f27c133;
}
.drop-file-input__label p:hover{
   color: #8f27c1;
}
.drop-file-input__label img {
    width: 80px;
    display: inline
}
.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

/* .drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: #f5f8ff;
    padding: 15px;
    border-radius: 20px;
} */

.drop-file-preview__item0 {
    /* position: relative; */
    /* display: flex; */
    margin-bottom: 10px;
    background-color: #f5f8ff;
    padding: 15px;
    border-radius: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    /* margin-bottom: 10px; */
    background-color: #f5f8ff;
    padding: 5px;
    border-radius: 20px;
}

.drop-file-preview__item img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}

.message {
    background: rgb(213, 217, 204);
    color: rgb(71, 66, 66);
    padding: 0.7rem;
    border-radius: 1rem 1rem 1rem 0;
    /* max-width: 28rem; */
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .message>span:nth-child(2){
    font-size: 1rem;
    color: var(--textColor);
    align-self: end;
}
  .own {
    align-self: flex-end;
    border-radius: 1rem 1rem 0 1rem;
    background: rgb(208, 201, 201);
  }
.copy-to-clipboard__button {
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: inherit;
    color: grey;
    /* border: '1px solid grey', // 1px black border */
    border-radius: 5px;
    /* padding: '5px 5px', */
    display: flex; 
    align-items: center; 
    cursor: pointer;
    gap: 5px; 
    z-index:1;
    font-size: 14px;
    /* fontWeight:"500", */
  }
  .chat__box {
    /* height: calc(97vh - var(--footer-height) - var(--header-height)); */
    /* height: calc(88vh - var(--header-height) - var(--footer-height)); */
    /* height: calc(88vh - var(--header-height) - 30px); */
    height: 77vh;
}