.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.css-1x5jdmq {
  padding: 10px 14px !important;
}

.css-p0rm37 {
  top: -6px !important;
}

.css-1uvydh2 {
  padding: 10px 14px !important;
}
