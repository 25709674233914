input[type="radio"] {
  accent-color: #8f27c1;
}

input[type="checkbox"] {
  accent-color: #8f27c1;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  margin: 16px 0;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  border-radius: 4px !important;
  border: none !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  background: none !important;
}

.css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: none !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: #10001a !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  width: 152px !important;
}

.css-pwxzbm {
  width: 152px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 12px !important;
  font-family: Manrope !important;
}

.css-1al598k {
  font-size: 12px !important;
  font-family: Manrope !important;
  color: #10001a !important;
  font-weight: 600;
}
.css-1al598k::before {
  border-bottom: none !important;
}

.css-1al598k {
  color: #10001a !important;
  text-align: right !important;
  font-family: Manrope !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.css-188mx6n-MuiSlider-root {
  color: #8f27c1 !important;
}

.css-1as9v07-MuiSlider-thumb {
  width: 15px !important;
  height: 15px !important;
}

.css-1as9v07-MuiSlider-thumb {
  border: 2px solid #8f27c1 !important;
  background: #ececf2 !important;
}

.MuiSlider-thumbColorPrimary .css-1qyrwb {
  border: 2px solid #8f27c1 !important;
  background: #ececf2 !important;
}

.css-1qyrwba {
  border: 2px solid #8f27c1 !important;
  background: #ececf2 !important;
}

.MuiSlider-sizeMid .css-1228x3t {
  color: #8f27c1 !important;
}

.css-ltlhnc-MuiSlider-root {
  color: #8f27c1 !important;
  height: 5px !important;
}

.css-37wsim {
  color: #8f27c1 !important;
  height: 5px !important;
}
