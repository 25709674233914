::-webkit-scrollbar {
  width: 0;
}


.scrollbar-width-half::-webkit-scrollbar {
  width: 8px;
  /* half the default width */
}

.scrollbar-width-half::-webkit-scrollbar-track {
  background-color: transparent;
  /* transparent background */
}

.scrollbar-width-half::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* scrollbar thumb color */
  border-radius: 4px;
}

.scrollable-markdown {
  overflow: auto;
  max-height: 350px;
  min-height: 350px;
}

.scrollable-markdown2 {
  overflow: auto;
  max-height: 180px;
  min-height: 180px;
}