@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat&family=Roboto:wght@300&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

body {
  background-color: #f8f9fd;
  /* font-feature-settings: "clig" off, "liga" off; */
  /* font-family: Lato; */
  font-family: "Manrope", sans-serif;
  font-style: normal;
}

.bg-img-login {
  background-image: url("../src/assets/ui_img/login-right-side-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
 border-radius: 20px;
  width: 35%;
  /* border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; */
}

.nav-width-open {
  width: calc(100% - 228px);
}

.nav-width-close {
  width: calc(100% - 90px);
}

.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}

input[name="infra"][type="radio"]:focus,
input[name="notebook"][type="radio"]:focus,
input[type="checkbox"]:focus {
  outline-color: #8f27c1;
}

.jupyter-page-hieght {
  height: calc(100vh - 128px);
}

.css-1m9128y {
  display: none;
}
