:root {
  --gen-color-1: #8f27c1;
  /* classic button - hover text color */
  --gen-color-2: #8f27c133;
  /* classic button - hover border color */
  --gen-color-3: #8f27c126;
  /* classic button - hover bg color */
}

.classic-button {
  border: 1px solid black;
  border-radius: 8px;
  color: black;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classic-button:hover {
  background-color: var(--gen-color-3);
  color: var(--gen-color-1);
  border-color: var(--gen-color-2);
}

.editOrg-button {
  border: 1px solid #8F27C1 ;
  border-radius: 8px;
  color: black;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editOrg-button:hover {
  background-color: var(--gen-color-3);
  color: var(--gen-color-1);
  border-color: var(--gen-color-2);
}

.classic-button:active {
  background-color: white;
  color: black;
  border-color: black;
}

.loading-button {
  border: 1px solid var(--gen-color-2);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gen-color-3);
  color: var(--gen-color-1);
  cursor: not-allowed;
}

.disabled-button {
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(218, 218, 218);
  color: rgb(142, 142, 142);
  cursor: not-allowed;
}


.general-button {
  border-radius: 12px;
  border: 1px solid #10001acc;
  font-size: 18px;
  font-weight: bold;
  padding: 18px 40px;
}

.general-button:hover {
  background-color: #8f27c11a;
  border-color: #8f27c10d;
  color: #8f27c1;
}

.cancel-button {
  border-radius: 12px;
  border: 1px solid #10001acc;
  font-size: 18px;
  font-weight: bold;
  padding: 18px 40px;
}

.cancel-button:hover {
  background-color: rgb(255, 238, 238);
  border-color: rgb(255, 238, 238);
  color: rgb(244, 117, 117);
}

.bor {
  border: 1px red solid;
}